import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../layout/layout";
import config from "../../data/SiteConfig";
import Img from "gatsby-image";

import { Container, Columns, Column, Section, Icon, BreadcrumbItem, Breadcrumb } from "bloomer";

export default class ShopPage extends React.Component {
  render() {
    const articles = this.props.data.articles.edges;

    const template = (
      <Layout>
        <Helmet>
          <title>{`Discover | ${config.siteTitle}`}</title>
        </Helmet>

        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <a>Discover</a>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <h1 className="title is-1">Discover</h1>
            <p class="subtitle is-4">
              Find out more about new music with our listening guides, programme notes, 
              interviews and learning resources
            </p>
            <hr />

            <Columns className="articles is-multiline">
              {articles.map(({ node }) => (
                <Column isSize={{ desktop: 4 }}>
                  <div class="column-card-full-height">
                    <div class="card-image">
                      <Link to={node.fields.slug}>
                        <Img
                          fluid={
                            node.frontmatter.featureImage.file.childImageSharp
                              .thumbnail
                          }
                        />
                      </Link>

                      <span class="tag is-dark article-tag">{node.frontmatter.type}</span>
                    </div>

                    <div class="card-teaser">
                      <h2 class="title is-4">{node.frontmatter.title}</h2>
                      <p class="subtitle is-6">{node.frontmatter.teaser}</p>
                    </div>

                    <div class="card-buttons">
                      <Link
                        renderas="button"
                        to={node.fields.slug}
                        className="button is-primary is-fullwidth"
                      >
                        <span>{node.timeToRead} minute read</span>{" "}
                        <Icon className="fas fa-chevron-right" />
                      </Link>
                    </div>
                  </div>
                </Column>
              ))}
            </Columns>
          </Container>
        </Section>
      </Layout>
    );
    return template;
  }
}

export const pageQuery = graphql`
  query DiscoverQuery {
    articles: allMarkdownRemark(
      filter: { fields: { template: { eq: "articles" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          timeToRead
          fields {
            slug
            id
          }
          frontmatter {
            title
            type
            teaser
            date
            featureImage {
              file {
                childImageSharp {
                  thumbnail: fluid(maxWidth: 640, maxHeight: 360, fit: COVER) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              caption
            }
          }
        }
      }
    }
  }
`;
